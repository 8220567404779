import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: (theme.palette.type === 'light') ? "#FFFFFF" : "#000000",
        color: (theme.palette.type === 'light') ? "#000000" : "#FFFFFF",
        maxWidth: 800,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #888888',
    },
}))(Tooltip);