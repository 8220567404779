import React, { useState, createContext } from 'react';

export const BotsContext = createContext();

export const BotsProvider = (props) => {
    const [bots, setBots] = useState(null);

    return (
        <BotsContext.Provider value={[bots, setBots]}>
            {props.children}
        </BotsContext.Provider>
    )
}