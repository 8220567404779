import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import BotCard from './BotCard';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { HtmlTooltip } from './HtmlToolTip';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { useLocalStore } from "../services/LocalStoreService";
import { socket } from '../context/SocketContext';
import { ServerContext } from '../context/ServerContext';
import { MessageContext } from '../context/MessageContext';
import { ConfigurationContext } from '../context/ConfigurationContext';
import { BotsContext } from '../context/BotsContext';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Divider from '@material-ui/core/Divider';
import differenceInHours from 'date-fns/differenceInHours';
import addHours from 'date-fns/addHours';
import { add } from 'date-fns'
import parse from 'date-fns/parse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSnackbar } from 'notistack';
import ipRegex from 'ip-regex';
import ipaddr from 'ipaddr.js';
import { Crypt } from 'hybrid-crypto-js';
import pckg from '../version.json';
import pckg2 from '../version2.json';
import cr from 'crypto-js';
import ReactMarkdown from 'react-markdown';
import mixpanel from 'mixpanel-browser';
import { encryptedUuid } from '../context/UuidContext';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { DropzoneDialog } from 'material-ui-dropzone';

const selectorButtonWidth = 80;
const selectorButtonHeight = 40;
const selectorButtonMargin = 8;
const selectorMouseMarginRight = 20;
const selectorMouseMarginLeft = 120;
const windowMargin = 16;
let selectorHeight = 64;
let selectorWidth;

const useStyles = makeStyles((theme) => ({
    outlinedButton: {
        width: selectorButtonWidth,
        height: selectorButtonHeight,
        border: '1px solid rgba(128, 128, 128, 0.5)',
        padding: '6px 8px',
        margin: '4px 4px',
        borderRadius: '4px',
        color: 'currentColor',
        textTransform: 'none',
        '&:hover': {
            border: '1px solid currentColor',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    filledButton: {
        width: selectorButtonWidth,
        height: selectorButtonHeight,
        border: '1px solid currentColor',
        padding: '6px 8px',
        margin: '4px 4px',
        borderRadius: '4px',
        color: 'currentColor',
        backgroundColor: 'rgba(5, 5, 5, 0.08)',
        textTransform: 'none',
        '&:hover': {
            border: '1px solid currentColor',
            backgroundColor: 'rgba(5, 5, 5, 0.08)',
        },
    },
    invisibleFiller: {
        width: selectorButtonWidth,
        height: selectorButtonHeight,
        border: 'none',
        padding: '6px 8px',
        margin: '4px 4px',
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    botselectordrawer: {
        width: '100%',
        flexShrink: 0,
    },
    botselectordrawerPaper: {
        width: '100%',
        height: selectorHeight,
    },
}));

function Copyright() {
    return (
        <Box color="#FFF" height={54} p={2}>
            <Typography variant="body2" align="center">
                {'Copyright © '}
                <Link style={{ color: '#ffffff', textDecoration: 'none' }} href="https://hodlerhacks.com/" target="_blank" rel="noopener">
                    hodlerhacks.com
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
        </Box>
    );
}

function BotSelector(props) {
    const classes = useStyles();
    let { bots, show, windowWidth, switchBot } = props;

    // Specific code for Bot Manager
    const currentBot = '';  // -> Bot Manager
    let adjusted = [{
        botID: 'Bot Mng',
        botname: 'Bot Manager',
        handle: '',
    }];
    bots.forEach(bot => {
        if (bot.status === 'enabled' || bot.status === 'new') {
            adjusted.push({
                botID: `Bot ${bot.botID}`,
                botname: bot.botname,
                handle: `bot${bot.botID}`
            })
        }
    });
    bots = adjusted;
    // --------------

    const availableWidth = windowWidth - windowMargin;
    let numBotsPerRow = Math.floor(availableWidth / (selectorButtonWidth + selectorButtonMargin));
    if (numBotsPerRow > bots.length) numBotsPerRow = bots.length;
    const numRows = Math.ceil(bots.length / numBotsPerRow);

    selectorHeight = 16 + numRows * (selectorButtonHeight + selectorButtonMargin);
    selectorWidth = numBotsPerRow * (selectorButtonWidth + selectorButtonMargin);

    // Fill the last row with empty fillers to make sure the first element is left-aligned
    let fillers = (numRows > 1 && numRows !== bots.length) ? numBotsPerRow - bots.length % numBotsPerRow : 0;
    if (fillers < 0) fillers = 0;

    let botsJustified = [];
    botsJustified.push(...bots);

    for (let i = 0; i < fillers; i++) {
        botsJustified.push({
            handle: 'filler',
        });
    }

    return (
        <SwipeableDrawer
            className={classes.botselectordrawer}
            anchor="top" open={show}
            onOpen={() => { return }}
            onClose={() => { return }}
            classes={{
                paper: classes.botselectordrawerPaper,
            }}
        >
            <div style={{ width: availableWidth, display: 'flex', justifyContent: 'center' }}>
                <div style={{ margin: 8, width: selectorWidth, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {botsJustified.map((bot, index) => {
                        if (index % numBotsPerRow === 0) {
                            return (
                                <React.Fragment key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {botsJustified.slice(index, index + numBotsPerRow).map(botRow => {
                                            return (
                                                (botRow.handle === currentBot ? (
                                                    <LightTooltip key={botRow.botID} title={botRow.botname}>
                                                        <Button className={classes.filledButton} onClick={() => { return }}>
                                                            {botRow.botID}
                                                        </Button>
                                                    </LightTooltip>
                                                ) : (botRow.handle !== 'filler') ?
                                                    (
                                                        <LightTooltip key={botRow.botID} title={botRow.botname}>
                                                            <Button className={classes.outlinedButton} onClick={() => switchBot(botRow)}>
                                                                {botRow.botID}
                                                            </Button>
                                                        </LightTooltip>
                                                    ) : (
                                                        <div className={classes.invisibleFiller} />
                                                    ))
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </SwipeableDrawer>
    );
}

const CustomSwitch = withStyles({
    checked: {
    },
    track: {
        backgroundColor: '#999',
    },
})(Switch);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

export default function BotManager() {
    const [mode, setMode] = useLocalStore('mode', 'light');
    const [badge, setBadge] = useLocalStore('badge', 'false');

    const [{ status, serverversion, environment, servertime, access, externalip, publicKey, userid, validInstallation }, setServer] = useContext(ServerContext);
    const [messages, setMessages] = useContext(MessageContext);
    const [configuration, setConfiguration] = useContext(ConfigurationContext);
    const [bots, setBots] = useContext(BotsContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const version = pckg2.version;
    
    const [openTT, setOpenTT] = useState(false);
    const [openTT2, setOpenTT2] = useState(false);
    const [openTT3, setOpenTT3] = useState(false);
    const [openTT4, setOpenTT4] = useState(false);
    const [openTT5, setOpenTT5] = useState(false);
    const [openBD, setOpenBD] = useState(true);
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDropzone, setOpenDropzone] = useState(false);
    const [openBackupSelect, setOpenBackupSelect] = useState(false);
    const [outdated, setOutdated] = useState(false);
    const [deleteBot, setDeleteBot] = useState({});
    const [input, setInput] = useState('');
    const [menuState, setMenuState] = useState(false);
    const [downloadState, setDownloadState] = useState(false);
    const [currenttime, setCurrenttime] = useState();
    const [newtime, setNewtime] = useState();
    const [timeshift, setTimeshift] = useState(configuration.timeshift || '0');
    const [telegramToken, setTelegramToken] = useState(configuration.telegramToken || '');
    const [telegramUsername, setTelegramUsername] = useState(configuration.telegramUsername || '');
    const [onepagemode, setOnepagemode] = useState(configuration.onepagemode || false);
    const [loginRequired, setLoginRequired] = useState(configuration.loginRequired || false);
    const [bbUsername, setBbUsername] = useState(configuration.bbUsername || '');
    const [bbPassword, setBbPassword] = useState(configuration.bbPassword || '');
    const [f2aRequired, setF2aRequired] = useState(configuration.f2aRequired || false);
    const [whitelistEnabled, setWhitelistEnabled] = useState(configuration.whitelistEnabled || false);
    const [whitelist, setWhitelist] = useState(configuration.whitelist || []);
    const [temporaryAccessAllowed, setTemporaryAccessAllowed] = useState(configuration.temporaryAccessAllowed || false);
    const [telegramChatId, setTelegramChatId] = useState(configuration.telegramChatId || null);
    const [newIP, setNewIP] = useState('');
    const [flexWidthDown, setFlexWidthDown] = useState(0);
    const [flexWidthUp, setFlexWidthUp] = useState(0);
    const [update, setUpdate] = useState(false);
    const [lastMsg, setLastMsg] = useLocalStore('last-msg', 0);
    const [dialog, setDialog] = useState({ number: 0, title: '', message: '', lock: false });
    const [dialog2, setDialog2] = useState({ title: '', message: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [restoreAck, setRestoreAck] = useState(false);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [checkIconColor, setcheckIconColor] = useState('#fff');
    const [telegramEnabled, setTelegramEnabled] = useState(false);
    const [updateMethod, setUpdateMethod] = useState('update');
    const [backupFiles, setBackupFiles] = useState([]);
    const [backupFile, setBackupFile] = useState();
    const [latestVersion, setLatestVersion] = useLocalStore('latest-version', version) || '0.0.0';
    const [showBotSelector, setShowBotSelector] = useState(false);
    const dv = cr.enc.Base64.parse(pckg.v).toString(cr.enc.Utf8);
    const bbv = dv;
    const checkNow = useRef(false);
    const lastRef = useRef(lastMsg);
    const dialogShownRef = useRef(false);

    let crypt = new Crypt({
        md: 'sha256',
        rsaStandard: 'RSA-OAEP',
    });

    useEffect(() => {
        document.title = `BB | Bot Manager`;

        // Listen to local store changes (when changed on another page)
        window.addEventListener('storage', () => {
            const newMode = localStorage.getItem('mode');
            setMode(newMode);

            const newVersion = localStorage.getItem('latest-version');
            setLatestVersion(newVersion);

            const newLast = localStorage.getItem('last-msg');

            if (newLast !== lastRef.current) {
                lastRef.current = newLast;

                let newMessages = [];

                // Only keep messages that haven't been shown yet (on any page)
                messages.forEach(msg => {
                    if (msg.number > newLast) newMessages.push(msg);
                });

                setMessages(newMessages);
            }
        });

        return () => {
        }
    }, []);

    useEffect(() => {
        setTimeshift(configuration.timeshift || '0');
        setTelegramToken(configuration.telegramToken || '');
        setTelegramUsername(configuration.telegramUsername || '');
        setOnepagemode(configuration.onepagemode || false);
        setWhitelistEnabled(configuration.whitelistEnabled || false);
        setLoginRequired(configuration.loginRequired || false);
        setBbUsername(configuration.bbUsername || '');
        setBbPassword(configuration.bbPassword || '');
        setF2aRequired(configuration.f2aRequired || false);
        setWhitelist(configuration.whitelist || []);
        setTemporaryAccessAllowed(configuration.temporaryAccessAllowed || false);
        setTelegramChatId(configuration.telegramChatId);

        // Show badge if access is restricted in any way, but Telegram bot is not configured
        if ((configuration.loginRequired || configuration.temporaryAccessAllowed) && (configuration.telegramToken === '' || configuration.telegramUsername === '' || !configuration.telegramChatId)) {
            setBadge('telegram');
        } else {
            setBadge('false');
        }

        setTelegramEnabled(configuration.telegramToken && configuration.telegramUsername && configuration.telegramChatId);

        // Check if the installation was restored from a backup, only do this once, not with every config change
        if (configuration.restored && !dialogShownRef.current) {
            dialogShownRef.current = true;

            let msg = 'Please take the following actions:\n\n';
            let code = 0;
            if (configuration.restored.telegram) {
                code += 2;
                msg += '- Re-enter your Telegram token and type /start in your Telegram bot\n';
            }
            if (configuration.restored.password) {
                code += 1;
                msg += '- Re-enable login and re-enter your password\n';
            }

            setDialog2({
                title: 'Your installation has been restored from a backup',
                message: msg,
            });

            setOpenDialog2(true);

            setBadge(code);
        }

        return () => {
        }
    }, [configuration]);

    const WindowSize = () => {
        const [size, setSize] = useState(0);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const drawerWidth = Math.min(480, WindowSize());
    const inputWidth = drawerWidth - 50;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        logo: {
            maxWidth: 60,
            padding: 10,
        },
        heroContent: {
            backgroundColor: (mode === 'light') ? '#fff' : '#000',
            padding: theme.spacing(4, 0, 2),
        },
        heroButtons: {
            marginTop: theme.spacing(4),
        },
        cardGrid: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardHeader: {
            backgroundColor: '#000',
        },
        cardContent: {
            flexGrow: 1,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        footer: {
            marginTop: 'auto',
            backgroundColor: '#000000',
        },
        bottomPush: {
            position: "fixed",
            width: '100%',
            bottom: 0,
            paddingBottom: 0,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 2,
            color: '#fff',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
            width: '80px',
        },
        button2: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
            width: '100px',
        },
        textinput: {
            width: inputWidth,
        }
    }));

    const classes = useStyles();

    const handleAddBot = () => {
        setOpenBD(true);
        if (socket) socket.emit("add-bot", access.token);
    }

    const theme = createMuiTheme({
        palette: {
            type: mode
        }
    });

    const StyledButton = withStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&:click': {
                backgroundColor: '#f00',
            },
        },
    })(Button);

    useEffect(() => {
        const outdated = (serverversion && (serverversion !== '0.0' && serverversion !== version));
        setOutdated(outdated);

        return () => {
        }
    }, [serverversion]);

    const action = key => (
        <React.Fragment>
            <Button
                style={{
                    color: '#bbbbbb',
                }}
                onClick={() => { closeSnackbar(key) }}>
                Dismiss
            </Button>
        </React.Fragment>
    );

    useEffect(() => {
        const url = window.location.origin;
        const localhost = url.includes('localhost');

        // Mixpanel
        if (userid) {
            mixpanel.init('6b67f3d61ee94b8f5c80b4affef4c4d9',
                {
                    host: "api-eu.mixpanel.com",
                });
            mixpanel.identify(userid);
            mixpanel.track('Bot Manager', {
                'localhost': localhost,
            });
        }

        return () => {
        }
    }, [userid]);

    useEffect(() => {
        if (bots !== null) setOpenBD(false);

        return () => {
        }
    }, [bots]);

    useEffect(() => {
        socket.on("notification", (msg, variant = 'error') => {
            enqueueSnackbar(msg, {
                variant: variant,
                autoHideDuration: 8000,
                action,
            });
        });

        return () => {
            socket.off("notification");
        }
    }, []);

    useEffect(() => {
        socket.on("backup-files", (files) => {
            setBackupFiles(files);
            if (files.length > 0) {
                setBackupFile(files[0].name);
            }
        });

        return () => {
            socket.off("backup-files");
        }
    }, []);

    const isNewerVersion = (newVer, oldVer) => {
        const oldParts = oldVer.split('.')
        const newParts = newVer.split('.')
        for (var i = 0; i < newParts.length; i++) {
            const a = ~~newParts[i] // parse int
            const b = ~~oldParts[i] // parse int
            if (a > b) return true
            if (a < b) return false
        }
        return false
    }

    useEffect(() => {
        // Accomodate for the settings and download icon
        let wdtDown = 100;
        let wdtUp = 100;
        const updateAvailable = isNewerVersion(latestVersion, version);

        setUpdate(updateAvailable);
        const logout = access && access.token;

        if (updateAvailable) {
            wdtDown += 140;
        } else {
            wdtDown += 40;
        }
        if (logout) {
            wdtDown += 50;
            wdtUp += 50;
        }

        setFlexWidthDown(wdtDown);
        setFlexWidthUp(wdtUp);

        return () => {
        }
    }, [latestVersion, version, access]);

    const handleDelete = (botID) => {
        let match = bots.find(b => b.botID === botID);
        setDeleteBot({
            botID: botID,
            botname: match.botname,
        });
        setInput('');
        setOpen(true);
    };

    const confirmDelete = (e) => {
        if (input === deleteBot.botname) {
            setOpen(false);
            if (socket) socket.emit("delete-bot", deleteBot.botID, access.token);
        }
    }

    const checkForUpdate = () => {
        checkNow.current = true;
        getMessages();
    }

    const onInput = (e) => {
        setInput(e.target.value);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getOpenUrl = (botpath) => {
        let currentUrl = window.location.href;
        let openUrl;
        if (currentUrl.includes(':5000')) currentUrl = currentUrl.replace(':5000', ':4000'); // For development only
        openUrl = currentUrl + botpath;
        return openUrl;
    }

    const handleUpdate = (botID, action, update) => {
        if (action === 'delete') handleDelete(botID);
        else if (action === 'dashboard') {
            const botpath = `bot${botID}`;
            const openUrl = getOpenUrl(botpath);
            if (configuration.onepagemode)
                window.open(openUrl, '_self', 'noopener');
            else
                window.open(openUrl, '_blank', 'noopener');
        } else if (action === 'status') {
            let match = bots.find(b => b.botID === botID);
            match.status = update;
            match.live = 'stopped';
            if (socket) {
                socket.emit("update-bot", match, access.token);
                setBots(bots);
            }
        } else if (action === 'headless') {
            if (socket) {
                socket.emit("toggle-bot-mode", botID, access.token);
            }
        }
    }

    const onBackupSelect = () => {
        setOpenBackupSelect(false);

        if (socket) socket.emit("restore-backup-file", backupFile, access.token);
    }

    const handleRestore = (method) => {
        closeDrawerDownloads();
        if (method === 'upload') setOpenDropzone(true);
        else if (method === 'select') setOpenBackupSelect(true);
    }

    const handleDarkmode = (e) => {
        (mode === 'light') ? setMode('dark') : setMode('light');
    }

    const handleUpdateOpen = (method = 'update') => {
        closeDrawerDownloads();
        setUpdateMethod(method);
        setOpenUpdate(true);
    }

    const confirmBbUpdate = () => {
        setOpenUpdate(false);

        if (socket) {
            if (updateMethod === 'update') {
                socket.emit("update-bb", access.token);
            }
            else if (updateMethod === 'reinstall') {
                socket.emit("reinstall-bb", access.token);
            }
        }
    }

    const handleUpdateClose = () => {
        setOpenUpdate(false);
    }

    const openDrawer = () => {
        // Update current and server times
        setCurrenttime(getHour(timeshift)[0]);
        setNewtime(getHour(timeshift)[1]);

        setMenuState(true);
    };

    const closeDrawer = () => {
        setMenuState(false);
    };

    const openDrawerDownloads = () => {
        if (socket) socket.emit("get-backup-files", access.token);

        setDownloadState(true);
    };

    const closeDrawerDownloads = () => {
        setDownloadState(false);
    };

    const handleConfirmedSave = () => {
        if (loginRequired && (bbUsername === '' || bbPassword === '')) {
            enqueueSnackbar('Please enter a username and password', {
                variant: 'error',
                autoHideDuration: 8000,
                action,
            });
            return;
        }

        const newPassword = ((configuration.restored && configuration.restored.password) && bbPassword === '') ? '***' : bbPassword;
        const newToken = ((configuration.restored && configuration.restored.telegram) && telegramToken === '') ? '***' : telegramToken;

        const newConfig = {
            timeshift: timeshift,
            telegramToken: newToken,
            telegramUsername: telegramUsername,
            onepagemode: onepagemode,
            loginRequired: loginRequired,
            bbUsername: bbUsername,
            bbPassword: newPassword,
            f2aRequired: f2aRequired,
            whitelistEnabled: whitelistEnabled,
            whitelist: whitelist,
            temporaryAccessAllowed: temporaryAccessAllowed,
            telegramChatId: telegramChatId,
        };

        const timestamp = new Date().getTime();

        let encryptedInput = newConfig;

        if (newPassword !== '************') {
            if (newPassword !== '' && newPassword !== '***') {
                encryptedInput = {
                    ...encryptedInput,
                    bbPassword: crypt.encrypt(publicKey, `${newPassword}@${timestamp}`),
                }
            }
        }

        if (newToken !== '************') {
            if (newToken !== '' && newToken !== '***') {
                encryptedInput = {
                    ...encryptedInput,
                    telegramToken: crypt.encrypt(publicKey, `${newToken}@${timestamp}`),
                }
            }
        }

        if (socket) socket.emit("store-settings", encryptedInput, access.token);

        if (newConfig.telegramToken !== '') newConfig.telegramToken = '************';
        if (newConfig.bbPassword !== '') newConfig.bbPassword = '************';

        setConfiguration(newConfig);

        closeDrawer();
    }

    const handleSave = () => {
        // Check if the Telegram bot has been setup properly
        const telegramApprovalEnabled =
            (f2aRequired === true && configuration.f2aRequired === false) ||
            (temporaryAccessAllowed === true && configuration.temporaryAccessAllowed === false);

        if (telegramApprovalEnabled && !telegramChatId) {
            enqueueSnackbar('Set up your Telegram bot and try again', {
                variant: 'error',
                autoHideDuration: 8000,
                action,
            });
        } else {
            handleConfirmedSave();
        }
    }

    const handleCancel = () => {
        setTimeshift(configuration.timeshift || '0');
        setTelegramToken(configuration.telegramToken || '');
        setTelegramUsername(configuration.telegramUsername || '');
        setOnepagemode(configuration.onepagemode || false);
        setLoginRequired(configuration.loginRequired || false);
        setBbUsername(configuration.bbUsername || '');
        setBbPassword(configuration.bbPassword || '');
        setF2aRequired(configuration.f2aRequired || false);
        setWhitelistEnabled(configuration.whitelistEnabled || false);
        setWhitelist(configuration.whitelist || []);
        setTemporaryAccessAllowed(configuration.temporaryAccessAllowed || false);
        setTelegramChatId(configuration.telegramChatId || null);
        closeDrawer();
    }

    const handleRefresh = () => {
        window.location.reload();
    }

    const handleDownload = (type) => {
        closeDrawerDownloads();

        axios.get(`/download/${type}`, {
            headers: {
                uuid: encryptedUuid(new Date()),
                tkn: access.token,
            },
            responseType: 'blob',
        })
            .then(function (res) {
                fileDownload(res.data, `${type}.zip`);
            })
            .catch(function (error) {
            })
    }

    const handleUpload = (files, event) => {
        const file = files[0];

        let formData = new FormData();
        formData.append('backupfile', file);

        axios.post('/upload-backup', formData, {
            headers: {
                uuid: encryptedUuid(new Date()),
                tkn: access.token,
                "Content-Type": "multipart/form-data",
            },
        })
        .then(res => {
            enqueueSnackbar('Backup file uploaded', {
                variant: 'success',
                autoHideDuration: 8000,
                action,
            });
        })
        .catch(err => {
            enqueueSnackbar('Uploading backup file failed', {
                variant: 'error',
                autoHideDuration: 8000,
                action,
            });
        })
    }

    const getHour = (shift) => {
        const now = new Date();
        const serverparsed = parse(servertime, 'yyyy/MM/dd HH:mm:ss', new Date());
        const diffServerLocal = differenceInHours(serverparsed, now);
        const server = addHours(now, diffServerLocal);
        const current = server.getHours().toString().padStart(2, '0') + ":" + server.getMinutes().toString().padStart(2, '0');
        let adjusted = server;
        if (shift !== 0) adjusted = add(server, { hours: shift });

        const newt = adjusted.getHours().toString().padStart(2, '0') + ":" + adjusted.getMinutes().toString().padStart(2, '0');
        return [current, newt];
    }

    useEffect(() => {
        setCurrenttime(getHour(timeshift)[0]);
        setNewtime(getHour(timeshift)[1]);

        return () => {
        }
    }, [servertime, timeshift]);

    const onTimeshift = (e) => {
        setTimeshift(e.target.value);
        setNewtime(getHour(e.target.value)[1]);
    }

    const onTelegramToken = (e) => {
        setTelegramToken(e.target.value);
    }

    const onTelegramUsername = (e) => {
        setTelegramUsername(e.target.value);
    }

    const onOnepagemode = (e) => {
        setOnepagemode(e.target.checked);
    }

    const onLoginRequired = (e) => {
        setLoginRequired(e.target.checked);
    }

    const onBbUsername = (e) => {
        setBbUsername(e.target.value);
    }

    const onBbPassword = (e) => {
        setBbPassword(e.target.value);
    }

    const onF2aRequired = (e) => {
        setF2aRequired(e.target.checked);
    }

    const onWhitelistEnabled = (e) => {
        // When enabled, make sure current IP address is on the whitelist
        if (e.target.checked && !whitelist.includes(normalizeIP(externalip).store)) {
            let newWhitelist = whitelist;
            newWhitelist.push(normalizeIP(externalip).store);
            setWhitelist(newWhitelist);
        }

        setWhitelistEnabled(e.target.checked);
    }

    const onTemporaryAccessAllowed = (e) => {
        setTemporaryAccessAllowed(e.target.checked);
    }

    const onNewIP = (e) => {
        if (/^[0-9,a-f,.,:]*$/.test(e.target.value)) setNewIP(e.target.value);
    }

    const addIP = () => {
        if (whitelist.includes(normalizeIP(newIP).store)) {
            enqueueSnackbar('IP address already whitelisted', {
                variant: 'error',
                autoHideDuration: 8000,
                action,
            });
        } else if (ipRegex.v4({ exact: true }).test(newIP) || ipRegex.v6({ exact: true }).test(newIP)) {
            let newWhitelist = whitelist;
            newWhitelist.push(normalizeIP(newIP).store);
            setNewIP('');
            setWhitelist(newWhitelist);
        } else {
            enqueueSnackbar('Invalid IP address', {
                variant: 'error',
                autoHideDuration: 8000,
                action,
            });
        }
    }

    const deleteIP = (ip) => {
        let newWhitelist = whitelist;
        newWhitelist = newWhitelist.filter(item => item !== ip)
        setWhitelist(newWhitelist);
    }

    const normalizeIP = (ipin) => {
        let ip4 = null;
        let ip6 = null;
        if (ipaddr.isValid(ipin)) {
            const ip = ipaddr.parse(ipin);
            if (ip.kind() === 'ipv6') {
                if (ip.isIPv4MappedAddress()) {
                    ip4 = ip.toIPv4Address().toString();
                }
                ip6 = ip.toString();
            } else {
                ip4 = ip.toString();
                ip6 = ip.toIPv4MappedAddress().toString();
            }
        }
        // Show ip4 to the user whenever possible, otherwise ip6
        // Always store ip6, and use that to check client ip versus whitelist
        return {
            show: ip4 ? ip4 : ip6,
            store: ip6,
        };
    }

    const onLogout = () => {
        if (socket) socket.emit("logout", access.token);
        setServer({
            status: status,
            serverversion: serverversion,
            servertime: servertime,
            access: {
                allow: false,
                reason: 'logged out',
                token: null,
            },
            externalip: externalip,
            publicKey: publicKey,
        });
    };

    const handleCloseDialog = async (e) => {
        const last = e.target.parentElement.id;
        setOpenDialog(false);

        // Remove shown message from array
        const newMessages = messages.slice(1, messages.length);
        setMessages(newMessages);

        if (socket) socket.emit("confirm-message", last, access.token);

        setLastMsg(last);
    };

    const capitalize = (str) => {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        if (messages.length > 0) {
            const msg = messages[0];
            if (msg.bbvNew) setLatestVersion(msg.bbvNew);

            // Only show message in case it matches current version, major version, or any
            if (msg.bbvReceive === 'any' || bbv.startsWith(msg.bbvReceive)) {

                // Check if the message contains a new version number, and if so, if BB is already on the new version
                let alreadyUpdated = msg.bbvNew ? bbv >= msg.bbvNew : false;

                // If already on the latest version, don't show the message
                if (!alreadyUpdated) {
                    if (msg.dialogWidth === null) {
                        const textLength = msg.message.length;
                        if (textLength < 400) setMaxWidth('sm');
                        else if (textLength < 800) setMaxWidth('md');
                        else if (textLength < 3000) setMaxWidth('lg');
                        else setMaxWidth('xl');
                    } else {
                        setMaxWidth(msg.dialogWidth);
                    }

                    const newDialogMsg = {
                        number: msg.number,
                        title: msg.title,
                        message: msg.message,
                        width: msg.dialogWidth,
                        lock: msg.lock,
                        updated: alreadyUpdated,
                    }

                    setDialog(newDialogMsg);
                    setOpenDialog(true);
                } else {
                    if (socket) socket.emit("confirm-message", msg.number, access.token);
                    // Remove shown message from array
                    const newMessages = messages.slice(1, messages.length);
                    setMessages(newMessages);
                }
            } else {
                if (socket) socket.emit("confirm-message", msg.number, access.token);
                // Remove message from array
                const newMessages = messages.slice(1, messages.length);
                setMessages(newMessages);
            }
        } else {
            setOpenDialog(false);
        }
    }, [messages]);

    async function getMessages() {
        if (socket) socket.emit("get-messages", access.token);

        if (checkNow.current) {
            setcheckIconColor('#ff9800');
            setTimeout(() => {
                setcheckIconColor('#fff');
            }, 1500);
        }

        checkNow.current = false;
    }

    const switchBot = (bot) => {
        const openUrl = getOpenUrl(bot.handle);
        if (configuration.onepagemode)
            window.open(openUrl, '_self', 'noopener');
        else
            window.open(openUrl, '_blank', 'noopener');
    }

    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [setWindowWidth])

    useEffect(() => {
        const handleMouseMove = (e) => {
            // Hide and show bot selector is triggered at different positions (so users can reach the other buttons in the app bar)
            if (showBotSelector) {
                if (e.clientY > 0 && e.clientY <= selectorHeight) {
                    setShowBotSelector(true);
                } else {
                    setShowBotSelector(false);
                }
            } else {
                let wdt;
                if (windowWidth < 600) {
                    wdt = flexWidthUp;
                } else {
                    wdt = flexWidthDown;
                }
                if (e.clientY > 0 && e.clientY <= selectorHeight && e.clientX > selectorMouseMarginLeft && e.clientX < windowWidth - wdt - selectorMouseMarginRight) {
                    setShowBotSelector(true);
                } else {
                    setShowBotSelector(false);
                }
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [windowWidth, flexWidthDown, showBotSelector]);

    return (<ThemeProvider theme={theme}>
        {!outdated ? (
            <React.Fragment>
                <Backdrop className={classes.backdrop} open={openBD}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {configuration.onepagemode && (
                    < BotSelector
                        bots={bots}
                        show={showBotSelector}
                        windowWidth={windowWidth}
                        switchBot={switchBot}
                    />
                )}
                <Dialog
                    open={openDialog}
                    fullWidth={true}
                    maxWidth={maxWidth}
                >
                    <DialogTitle id="dialog-title">{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description">
                            <ReactMarkdown linkTarget="_blank">
                                {dialog.message}
                            </ReactMarkdown>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" id={dialog.number} onClick={handleCloseDialog} color="secondary" disabled={dialog.lock}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialog2}
                    fullWidth={true}
                >
                    <DialogTitle>{dialog2.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <ReactMarkdown linkTarget="_blank">
                                {dialog2.message}
                            </ReactMarkdown>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpenDialog2(false)} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                    <CssBaseline />
                    <AppBar position="relative" className={classes.appBar}>
                        <Toolbar>
                            <img src="/logo192.png" alt="Balance Bot Logo" className={classes.logo} />
                                <Grid container direction="row" alignItems="center" nowrap="true">
                                    <Grid item xs zeroMinWidth>
                                        <Box mt={1.5}>
                                            <Typography variant="h6" color="inherit" noWrap>
                                                Balance Bot
                                            </Typography>
                                        </Box>
                                        <Box mt={-1} style={{ color: '#959FD6' }}>
                                            <Typography variant="caption" color="inherit" noWrap>
                                                v{version} {environment === 'staging' && ('(staging)')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Hidden xsDown>
                                        <FlexToolbar wdt={flexWidthDown} update={update} logout={access && access.token} onLogout={onLogout} badge={badge} openDrawer={openDrawer} openDrawerDownloads={openDrawerDownloads} handleUpdateOpen={handleUpdateOpen} checkForUpdate={checkForUpdate} checkIconColor={checkIconColor} />
                                    </Hidden>
                                    <Hidden smUp>
                                        <FlexToolbar wdt={flexWidthUp} update={update} logout={access && access.token} onLogout={onLogout} badge={badge} openDrawer={openDrawer} openDrawerDownloads={openDrawerDownloads} handleUpdateOpen={handleUpdateOpen} checkForUpdate={checkForUpdate} checkIconColor={checkIconColor} />
                                    </Hidden>
                                </Grid>
                        </Toolbar>
                    </AppBar>
                <DropzoneDialog
                    dropzoneText={'Drop a backup.zip file here or click to browse'}
                    // acceptedFiles={['application/x-zip-compressed']}
                    filesLimit={1}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    maxFileSize={5000000}
                    open={openDropzone}
                    onClose={() => setOpenDropzone(false)}
                    onSave={(files, event) => {
                        event.preventDefault();
                        if (files[0].name.startsWith('backup') && files[0].name.endsWith('.zip')) {
                            setOpenDropzone(false);
                            handleUpload(files, event);
                        } else {
                            enqueueSnackbar('Please upload a backup.zip file', {
                                variant: 'error',
                                autoHideDuration: 8000,
                                action,
                            });
                        }
                    }}
                    showPreviewsInDropzone={true}
                    showFileNamesInPreview={true}
                    showPreviews={false}
                    useChipsForPreview={true}
                    showAlerts={false}
                />
                <SwipeableDrawer
                    className={classes.drawer}
                    anchor="right" open={downloadState} onOpen={() => { return }} onClose={closeDrawerDownloads}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <div style={{ padding: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h4">
                                    Download Files
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClickAwayListener onClickAway={() => setOpenTT3(false)}>
                                    <HtmlTooltip
                                        interactive
                                        title={
                                            <React.Fragment>
                                                <Box m={2}>
                                                    <div><b>{"Download Backup"}</b></div>
                                                    <div>{"You can download a backup of your configuration files. This helps to restore your bot(s) when you'd like to do a new reinstallation or move your installation to another machine. Sensitive data (e.g. API keys, password, Telegram token) are stripped before downloading."}</div>
                                                    <div>&nbsp;</div>
                                                    <div>{"If you've selected 'require login to access', this will be disabled in the downloaded onfiguration. This to avoid getting locked out if you use the backup to restore your bots. Make sure to re-enable login at first use."}</div>
                                                    <div>&nbsp;</div>
                                                    <div>{"If you've enabled a Telegram bot, you will have to re-enter your Telegram token in the settings, and use the /start command in the Telegram bot to enable communication between BB and Telegram."}</div>
                                                    <div>&nbsp;</div>
                                                    <div>{"NOTE: this will also create a new full backup on the server."}</div>
                                                    <div>&nbsp;</div>
                                                    <div><b>{"Download Logs"}</b></div>
                                                    <div>{"You can download log files containing output from your bot(s). This could help understand / debug possible issues with your installation."}</div>
                                                </Box>
                                            </React.Fragment>
                                        }
                                        onClose={() => setOpenTT3(false)}
                                        open={openTT3}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <IconButton onClick={openTT3 ? () => setOpenTT3(false) : () => setOpenTT3(true)}>
                                            <InfoOutlinedIcon color="secondary" />
                                        </IconButton>
                                    </HtmlTooltip>
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12}>
                                <Box pl={0} mt={-2}>
                                    <Typography variant="overline" color="textPrimary">
                                        Download configuration backup or log files
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDownload('backup')}
                                    className={classes.button2}
                                >
                                    Backup
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDownload('logs')}
                                    className={classes.button2}
                                >
                                    Logs
                                </Button>
                            </Grid>
                        </Grid>
                        <Box pt={3} />
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"}>Note: 'Backup' will also create a new full backup on the server</Typography>
                        </Grid>
                        <Box pt={5} />
                        <Divider style={{height: 3} } />
                        <Box pt={5} />
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h4">
                                    Restore Configuration
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClickAwayListener onClickAway={() => setOpenTT4(false)}>
                                    <HtmlTooltip
                                        interactive
                                        title={
                                            <React.Fragment>
                                                <Box m={2}>
                                                    <div><b>{"Upload Backup"}</b></div>
                                                    <div>{"You can upload a backup of your configuration files (backup.zip). This will restore your installation based on a previously downloaded backup."}</div>
                                                    <div>&nbsp;</div>
                                                    <div><b>{"Select Backup"}</b></div>
                                                    <div>{"You can select from the automatically made backups of your configuration files. This will restore your installation based on the selected backup."}</div>
                                                    <div>&nbsp;</div>
                                                    <div>{"Note: restoring your configuration from a backup will override your Bot Manager settings and bots that you have already configured as part of this installation, so please use with care."}</div>
                                                    <div>&nbsp;</div>
                                                    <div>{"To execute the restore process, you will be asked to confirm in your Telegram bot."}</div>
                                                </Box>
                                            </React.Fragment>
                                        }
                                        onClose={() => setOpenTT4(false)}
                                        open={openTT4}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <IconButton onClick={openTT4 ? () => setOpenTT4(false) : () => setOpenTT4(true)}>
                                            <InfoOutlinedIcon color="secondary" />
                                        </IconButton>
                                    </HtmlTooltip>
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12}>
                                <Box pl={0} mt={-2}>
                                    <Typography variant="overline" color="textPrimary">
                                        Restore your bot(s) from a backup
                                    </Typography>
                                </Box>
                                {(bots.length > 0 && !telegramEnabled) && (
                                    <Box pl={1} mt={1}>
                                        <Typography variant="subtitle2" color="textPrimary">
                                            Note: you need to setup your Telegram bot first
                                        </Typography>
                                    </Box>
                                ) }
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleRestore('upload')}
                                    className={classes.button2}
                                    disabled={(bots.length > 0 && !telegramEnabled) || !restoreAck}
                                >
                                    Upload
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleRestore('select')}
                                    className={classes.button2}
                                    disabled={(bots.length > 0 && !telegramEnabled) || !restoreAck}
                                >
                                    Select
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" name="restore-ack" disabled={false} checked={restoreAck} onChange={() => setRestoreAck(!restoreAck)} />}
                                    label={<Typography variant={"subtitle2"}>I understand 'restore' will delete & replace my current bot(s)</Typography>}
                                />
                            </Grid>
                        </Grid>
                        <Box pt={5} />
                        <Divider style={{ height: 3 }} />
                        <Box pt={5} />
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h4">
                                    Application Control
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClickAwayListener onClickAway={() => setOpenTT5(false)}>
                                    <HtmlTooltip
                                        interactive
                                        title={
                                            <React.Fragment>
                                                <Box m={2}>
                                                    <div><b>{"Update Balance Bot"}</b></div>
                                                    <div>{"This updates Balance Bot to a newer version, if available."}</div>
                                                    <div>&nbsp;</div>
                                                    <div><b>{"Reinstall Balance Bot"}</b></div>
                                                    <div>{"This reinstalls Balance Bot to the latest version. Note: your configuration will be kept."}</div>
                                                </Box>
                                            </React.Fragment>
                                        }
                                        onClose={() => setOpenTT5(false)}
                                        open={openTT5}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <IconButton onClick={openTT5 ? () => setOpenTT5(false) : () => setOpenTT5(true)}>
                                            <InfoOutlinedIcon color="secondary" />
                                        </IconButton>
                                    </HtmlTooltip>
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12}>
                                <Box pl={0} mt={-2}>
                                    <Typography variant="overline" color="textPrimary">
                                        Manage the Balance Bot installation
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleUpdateOpen('update')}
                                    className={classes.button2}
                                >
                                    Update
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleUpdateOpen('reinstall')}
                                    className={classes.button2}
                                >
                                    Reinstall
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </SwipeableDrawer>
                <SwipeableDrawer
                        className={classes.drawer}
                        anchor="right" open={menuState} onOpen={() => {return}} onClose={handleCancel}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Toolbar />
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={2}>
                                {(badge === 'telegram') && (
                                    <Alert severity="error">Configure and start your Telegram bot when enabling login or allowing temporary access from other IP addresses</Alert>
                                )}
                                {(badge === 1) && (
                                    <Alert severity="error">Re-enable login and re-enter your password</Alert>
                                )}
                                {(badge === 2) && (
                                    <Alert severity="error">Re-enter your Telegram token and type /start in your Telegram bot</Alert>
                                )}
                                {(badge === 3) && (
                                <Box>
                                    <Alert severity="error">Re-enter your Telegram token and type /start in your Telegram bot</Alert>
                                    <Alert severity="error">Re-enable login and re-enter your password</Alert>
                                </Box>
                                )}
                                <Grid item xs={10}>
                                    <Typography variant="h4">
                                        General Settings
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <ClickAwayListener onClickAway={() => setOpenTT(false)}>
                                        <HtmlTooltip
                                            interactive
                                            title={
                                                <React.Fragment>
                                                    <Box m={2}>
                                                        <div><b>{"Server timezone adjustment"}</b></div>
                                                        <div>{"If you're hosting Balance Bot in the cloud, and the server's timezone is different from your local timezone, you can use this setting to adjust for that so that timestamps for orders and other events are provided in your local timezone. This setting will be applied to all your individual bots."}</div>
                                                        <div>&nbsp;</div>
                                                        <div><b>{"Telegram Bot"}</b></div>
                                                        <div>{"You can receive notifications from Bot Manager and your bot(s) on Telegram. To do so, take the following steps:"}</div>
                                                        <div>&nbsp;</div>
                                                        <div>{"1. Create a Telegram bot by sending '/newbot' to the BotFather (Telegram's uber bot): "}<Link href="https://t.me/botfather" target="_blank" rel="noopener" color="secondary">https://t.me/botfather</Link></div>
                                                        <div>{"2. Answer BotFather's questions, i.e. pick a name and a username for your bot"}</div>
                                                        <div>{"3. Copy the token provided by BotFather and paste it here in the settings"}</div>
                                                        <div>{"4. Enter your personal Telegram username (your name on Telegram preceded by @, NOT the username for your Telegram bot) here in the settings"}</div>
                                                        <div>{"5. Open your Telegram bot using the link provided by BotFather: "}<Link href="#" color="secondary">t.me/[botname]</Link></div>
                                                        <div>{"6. Tap the 'start' button at the bottom, or type '/start' for further instructions"}</div>
                                                        <div>&nbsp;</div>
                                                        <div><b>{"One page Mode"}</b></div>
                                                        <div>{"By enabling 'one page mode', dashboards will be opened within the same browser tab. When disabled, dashboards will be opened in new browser tabs."}</div>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            onClose={() => setOpenTT(false)}
                                            open={openTT}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                        >
                                            <IconButton onClick={openTT ? () => setOpenTT(false) : () => setOpenTT(true)}>
                                                <InfoOutlinedIcon color="secondary" />
                                            </IconButton>
                                        </HtmlTooltip>
                                    </ClickAwayListener>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <InputLabel id="server-timezone">Adjust for server timezone</InputLabel>
                                        <Select
                                            labelId="server-timezone"
                                            value={timeshift}
                                            onChange={onTimeshift}
                                            className={classes.textinput}
                                        >
                                            <MenuItem value={12}>+12:00</MenuItem>
                                            <MenuItem value={11}>+11:00</MenuItem>
                                            <MenuItem value={10}>+10:00</MenuItem>
                                            <MenuItem value={9}>+09:00</MenuItem>
                                            <MenuItem value={8}>+08:00</MenuItem>
                                            <MenuItem value={7}>+07:00</MenuItem>
                                            <MenuItem value={6}>+06:00</MenuItem>
                                            <MenuItem value={5}>+05:00</MenuItem>
                                            <MenuItem value={4}>+04:00</MenuItem>
                                            <MenuItem value={3}>+03:00</MenuItem>
                                            <MenuItem value={2}>+02:00</MenuItem>
                                            <MenuItem value={1}>+01:00</MenuItem>
                                            <MenuItem value={0}>No adjustment</MenuItem>
                                            <MenuItem value={-1}>-01:00</MenuItem>
                                            <MenuItem value={-2}>-02:00</MenuItem>
                                            <MenuItem value={-3}>-03:00</MenuItem>
                                            <MenuItem value={-4}>-04:00</MenuItem>
                                            <MenuItem value={-5}>-05:00</MenuItem>
                                            <MenuItem value={-6}>-06:00</MenuItem>
                                            <MenuItem value={-7}>-07:00</MenuItem>
                                            <MenuItem value={-8}>-08:00</MenuItem>
                                            <MenuItem value={-9}>-09:00</MenuItem>
                                            <MenuItem value={-10}>-10:00</MenuItem>
                                            <MenuItem value={-11}>-11:00</MenuItem>
                                            <MenuItem value={-12}>-12:00</MenuItem>
                                        </Select>
                                        <FormHelperText>Current server time: {currenttime} - Adjusted server time: {newtime}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="telegram-token"
                                        name="telegram-token"
                                        label="Telegram Token"
                                        helperText="Token for your Telegram bot as provided by BotFather"
                                        type="password"
                                        value={telegramToken}
                                        onChange={onTelegramToken}
                                        className={classes.textinput}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="telegram-username"
                                        name="telegram-username"
                                        label="Telegram Username"
                                        helperText="Your Telegram username"
                                        value={telegramUsername}
                                        onChange={onTelegramUsername}
                                        className={classes.textinput}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                    control={<Checkbox color="secondary" name="one-page-mode" checked={onepagemode} onChange={onOnepagemode} />}
                                        label="Enable one page mode"
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h4">
                                        Security Settings
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <ClickAwayListener onClickAway={() => setOpenTT2(false)}>
                                        <HtmlTooltip
                                            interactive
                                            title={
                                                <React.Fragment>
                                                    <Box m={2}>
                                                        <div><b>{"Security Settings"}</b></div>
                                                        <div>{"If you're running Balance Bot in the cloud, it is highly recommended to only allow access from selected IP addresses (like your home IP address). Alternatively, or additionally, you may want to require login before getting access. You should use at least one of these options to prevent others from accessing your Balance Bot deployment."}</div>
                                                        <div>&nbsp;</div>
                                                        <div><b>{"Require login to access"}</b></div>
                                                        <div>{"Check this option to require logging in using a username and password. For improved security, after logging in, your session will expire after 60 minutes. Optionally, you can activate Two Factor Authentication (2FA) through your Telegram bot (requires Telegram configuration, see General Settings above)."}</div>
                                                        <div>&nbsp;</div>
                                                        <div><b>{"Enable IP whitelist"}</b></div>
                                                        <div>{"Check this option to allow access to Balance Bot from selected IP addresses only, and add your trusted IP address(es) to the IP whitelist. By default, your current IP address is added to the IP whitelist when enabling the option, to prevent you from being locked out."}</div>
                                                        <div>&nbsp;</div>
                                                        <div><b>{"Allow temporary access"}</b></div>
                                                        <div>{"There may be occassions when you want to access Balance Bot from an IP address that is not whitelisted. By checking the temporary access option, if you're trying to access Balance Bot from a non-whitelisted IP address, you will receive a message in your Telegram Bot and you can approve temporary access from that IP address (requires Telegram configuration, see General Settings above). If you have login enabled, you will be asked for your username and password instead."}</div>
                                                        <div>&nbsp;</div>
                                                        <div>{"Note: for security reasons, it is NOT recommended to access Balance Bot over public wifi networks."}</div>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            onClose={() => setOpenTT2(false)}
                                            open={openTT2}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                        >
                                            <IconButton onClick={openTT2 ? () => setOpenTT2(false) : () => setOpenTT2(true)}>
                                                <InfoOutlinedIcon color="secondary" />
                                            </IconButton>
                                        </HtmlTooltip>
                                    </ClickAwayListener>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                                <Box pl={1} mt={-2}>
                                    <Typography variant="overline" color="textPrimary">
                                        For security reasons, don't use public wifi networks
                                    </Typography>
                                </Box>
                                <Grid item xs={12}>
                                        {(telegramToken === '' || telegramUsername === '' || !telegramChatId) && !loginRequired ? (
                                            <LightTooltip title="Requires Telegram bot to be set up and started with /start">
                                                <FormControlLabel
                                                    control={<Checkbox color="secondary" name="login-required" disabled={true} checked={loginRequired} onChange={onLoginRequired} />}
                                                    label="Require login to access"
                                                />
                                            </LightTooltip>
                                        ) : (
                                                <FormControlLabel
                                                    control={<Checkbox color="secondary" name="login-required" disabled={false} checked={loginRequired} onChange={onLoginRequired} />}
                                                    label="Require login to access"
                                                />
                                        )}
                                </Grid>
                                <Box p={2} display={loginRequired ? 'inline' : 'none'} bgcolor={mode === 'dark' ? "#757575" : "#e0e0e0"} width="100%" >
                                    <Grid item xs={12}>
                                        <TextField
                                            id="bb-username"
                                            name="bb-username"
                                            label="Username"
                                            helperText="Your Balance Bot username"
                                            value={bbUsername}
                                            onChange={onBbUsername}
                                            className={classes.textinput}
                                            disabled={!loginRequired}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="bb-password"
                                            name="bb-password"
                                            label="Password"
                                            helperText="Your Balance Bot password"
                                            type="password"
                                            value={bbPassword}
                                            onChange={onBbPassword}
                                            className={classes.textinput}
                                            disabled={!loginRequired}
                                            autoComplete='off'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(telegramToken === '' || telegramUsername === '' || !telegramChatId) && !f2aRequired ? (
                                            <LightTooltip title="Requires Telegram bot to be set up and started with /start">
                                                <FormControlLabel
                                                    control={<Checkbox color="secondary" name="f2a-required" disabled={true} checked={f2aRequired} onChange={onF2aRequired} />}
                                                    label="Require 2FA through Telegram bot to log in"
                                                />
                                            </LightTooltip>
                                        ) : (
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="f2a-required" disabled={false} checked={f2aRequired} onChange={onF2aRequired} />}
                                                label="Require 2FA through Telegram bot to log in"
                                            />
                                        )}
                                    </Grid>
                                </Box>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox color="secondary" name="whitelist-enabled" checked={whitelistEnabled} onChange={onWhitelistEnabled} />}
                                        label="Enable IP whitelist"
                                    />
                                </Grid>
                                <Box p={2} display={whitelistEnabled ? 'inline' : 'none'} bgcolor={mode === 'dark' ? "#757575" : "#e0e0e0"} width="100%" >
                                    <Grid item xs={12}>
                                        <Typography variant="overline" color="textSecondary">
                                            IP Whitelist
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List dense={true}>
                                            {whitelist.map((ip) => ip === normalizeIP(externalip).store ? (
                                                <ListItem key={ip}>
                                                    <ListItemText
                                                        primary={normalizeIP(ip).show}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Button color="secondary" edge="end" size="small" disableFocusRipple disableRipple disabled>
                                                            Current IP
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ) : (
                                                <ListItem key={ip}>
                                                    <ListItemText
                                                        primary={normalizeIP(ip).show}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" size="small" onClick={() => deleteIP(ip)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item container justify="space-between" xs={12}>
                                        <Grid item xs={9}>
                                            <TextField
                                                id="new-ip"
                                                name="new-ip"
                                                label="IP Address"
                                                value={newIP}
                                                onChange={onNewIP}
                                                className={classes.textinput}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Fab size="small" color="secondary" onClick={addIP}>
                                                <AddIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={12}>
                                            {(telegramToken === '' || telegramUsername === '' || !telegramChatId) && !temporaryAccessAllowed ? (
                                                <LightTooltip title="Requires Telegram bot to be set up and started with /start">
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary" name="temporary-access-allowed" disabled={true} checked={temporaryAccessAllowed} onChange={onTemporaryAccessAllowed} />}
                                                        label="Allow controlled temporary access from other IPs"
                                                        />
                                                </LightTooltip>
                                            ) : (
                                                <FormControlLabel
                                                    control={<Checkbox color="secondary" name="temporary-access-allowed" disabled={false} checked={temporaryAccessAllowed} onChange={onTemporaryAccessAllowed} />}
                                                    label="Allow controlled temporary access from other IPs"
                                                />
                                            )}
                                    </Grid>
                                </Box>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCancel}
                                        className={classes.button}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        className={classes.button}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid> 
                        </div>
                </SwipeableDrawer>
                    <main>
                        <div className={classes.heroContent}>
                            <Container maxWidth="sm">
                                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                    Bot Manager
                                </Typography>
                                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                    Create, start, stop &amp; delete your bots
                                </Typography>
                                <div className={classes.heroButtons}>
                                    <Grid container spacing={2} justify="center">
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={handleAddBot}>
                                                Add new bot
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Container>
                        </div>
                        <Container className={classes.cardGrid} maxWidth="md">
                            <Grid container spacing={4}>
                                {bots && bots.length > 0 && bots.map((bot) => (
                                    <BotCard key={bot.botID} bot={bot} mode={mode} callback={handleUpdate} />
                                ))}
                            </Grid>
                            <Box mb={4} />
                        </Container>
                    </main>
                    <div className={classes.bottomPush}>
                        <footer className={classes.footer}>
                            <Grid container alignItems="center">
                                <Grid container item xs={12} sm={6} md={3} alignItems="center" justify="flex-start">
                                    <Grid item>
                                        <FormControlLabel
                                            onChange={handleDarkmode}
                                            value={mode}
                                            control={<CustomSwitch checked={mode === 'dark'} color="secondary" />}
                                            label={<Typography variant="button"><Box color="#FFF">Light</Box></Typography>}
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <StyledButton disableRipple onClick={handleDarkmode}><Typography variant="button">
                                            <Box color="#FFF">Dark</Box>
                                        </Typography></StyledButton>
                                    </Grid>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item xs={false} sm={6} md={6}>
                                        <Copyright />
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid item xs={false} md={3}></Grid>
                                    </Hidden>
                                </Hidden>
                            </Grid>
                        </footer>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography color="textPrimary">
                                    Warning: your bot, its configuration, and all its data will be deleted. Enter the bot name to confirm deletion: <b>{deleteBot.botname}</b>
                                </Typography>
                            </DialogContentText>
                            <TextField
                                autoFocus
                                color={(mode === 'light') ? "primary" : "secondary"}
                                margin="dense"
                                id="botname"
                                label="Bot name"
                                type="text"
                                value={input}
                                onChange={onInput}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose} color={(mode === 'light') ? "primary" : "secondary"}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={confirmDelete} color={(mode === 'light') ? "primary" : "secondary"}>
                                Delete bot
                            </Button>
                        </DialogActions>
                    </Dialog>
                <Dialog open={openBackupSelect} onClose={() => {setOpenBackupSelect(false)}}>
                    <DialogTitle>Select Backup</DialogTitle>
                    <DialogContent>
                        {backupFiles.length > 0 ? (
                            <Box>
                                <DialogContentText>
                                    <Typography color="textPrimary">
                                        Please select a backup below. This backup will be used to restore your configuration
                                    </Typography>
                                </DialogContentText>
                                <Select
                                    labelId="backup-selection"
                                    value={backupFile}
                                    onChange={(event) => {setBackupFile(event.target.value)}}
                                    className={classes.textinput}
                                >
                                    {backupFiles.map((file) =>
                                        (
                                            <MenuItem value={file.name}>{file.name}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </Box>
                            ) : (
                                <DialogContentText>
                                    <Typography color="textPrimary">
                                        There are no backups available yet. Backup are made automatically every day
                                    </Typography>
                                </DialogContentText>
                            )} 
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => {setOpenBackupSelect(false)}} color={(mode === 'light') ? "primary" : "secondary"}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={backupFiles.length === 0} onClick={onBackupSelect} color={(mode === 'light') ? "primary" : "secondary"}>
                            Restore Backup
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openUpdate} onClose={handleUpdateClose}>
                    {validInstallation ? (
                        <>
                            <DialogTitle>Confirm {capitalize(updateMethod)}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography color="textPrimary">
                                        Do you want to {updateMethod} to the latest version? Balance Bot will be unavailable until the process has completed. This may take a few minutes. Just wait for the Bot Manager to reappear.
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={handleUpdateClose} color={(mode === 'light') ? "primary" : "secondary"}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={confirmBbUpdate} color={(mode === 'light') ? "primary" : "secondary"}>
                                    {capitalize(updateMethod)}
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogTitle>Manual {capitalize(updateMethod)} Required</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography color="textPrimary">
                                        Your installation does not support {updateMethod}s from the browser. Please perform a manual {updateMethod}.
                                    </Typography>
                                </DialogContentText>
                                <DialogContentText>
                                    <Typography color="textPrimary">
                                        Possible causes:
                                        <ol>
                                            <li>You haven't installed BB using the official installer</li>
                                            <li>You're running BB in a Docker container</li>
                                        </ol>
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={handleUpdateClose} color={(mode === 'light') ? "primary" : "secondary"}>
                                    OK
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                </React.Fragment>
            ) : (
                <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="overline" color="secondary">
                                New version has been installed
                            </Typography>
                            <DialogContentText>
                            </DialogContentText>
                            <Typography variant="overline" color="textPrimary">
                                Please refresh the browser window to load the latest version
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleRefresh} color="secondary">
                            Refresh
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }
    </ThemeProvider>
    )
}

const FlexToolbar = (props) => {
    const { wdt, update, logout, onLogout, badge, openDrawer, openDrawerDownloads, handleUpdateOpen, checkForUpdate, checkIconColor } = props;

    return (
        <Grid item xs container direction="row" alignItems="center" justify="flex-end" style={{ maxWidth: wdt }}>
            {(update) ? (
                <Hidden xsDown>
                    <Grid item style={{ width: '130px' }}>
                        <Chip onClick={() => handleUpdateOpen('update')} label='Update Available' style={{ backgroundColor: '#959FD6', color: 'white' }} />
                    </Grid>
                </Hidden>
            ) : (
                <Hidden xsDown>
                    <Grid item style={{ width: '40px' }}>
                        <LightTooltip title="Check for updates">
                            <IconButton onClick={checkForUpdate}>
                                <AutorenewIcon style={{ color: checkIconColor }} />
                            </IconButton>
                        </LightTooltip>
                    </Grid>
                </Hidden>
            )}
            <Grid item>
                <LightTooltip title="Admin Tools">
                    <IconButton onClick={openDrawerDownloads}>
                        <DescriptionOutlinedIcon style={{ color: '#fff' }} />
                    </IconButton>
                </LightTooltip>
            </Grid>
            <Grid item>
                <LightTooltip title="Settings">
                    <IconButton onClick={openDrawer}>
                        {(badge === 'false') ? (
                            <MenuIcon style={{ color: '#fff' }} />
                        ) : (
                            <Badge badgeContent={'!'} color="secondary">
                                <MenuIcon style={{ color: '#fff' }} />
                            </Badge>
                        )}
                    </IconButton>
                </LightTooltip>
            </Grid>
            {(logout) && (
                <Grid item style={{ width: '48px' }}>
                    <LightTooltip title="Logout">
                        <IconButton onClick={onLogout}>
                            <ExitToAppIcon style={{ color: '#ffffff' }} />
                        </IconButton>
                    </LightTooltip>
                </Grid>
            )}
        </Grid>
    );
}