import './App.css';
import Switch from './components/Switch';
import SocketService from './services/SocketService';
import { ServerProvider } from './context/ServerContext';
import { MessageProvider } from './context/MessageContext';
import { ConfigurationProvider } from './context/ConfigurationContext';
import { BotsProvider } from './context/BotsContext';
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider maxSnack={5}>
      <ServerProvider>
        <MessageProvider>
          <ConfigurationProvider>
            <BotsProvider>
              <SocketService />
              <CssBaseline />
              <Switch />
            </BotsProvider>
          </ConfigurationProvider>
        </MessageProvider>
      </ServerProvider>
    </SnackbarProvider>
    );
}

export default App;