import React, { useContext, useState, useEffect } from 'react';
import BotManager from './BotManager';
import Public from './Public';
import { ServerContext } from '../context/ServerContext';

function Switch() {
    const { status, access } = useContext(ServerContext)[0];
    const [goPublic, setGoPublic] = useState(true);

    useEffect(() => {
        setGoPublic(!access.allow || status === 'disconnected');

        return () => {
        }
    }, [access, status]);

    return (
        <div>
            {goPublic ? (
                <Public />
            ) : (
                <BotManager />
            )}
        </div>
    );
}

export default Switch;