import { Crypt } from 'hybrid-crypto-js';

const uuid = localStorage.getItem('uuid');
const publicKey = localStorage.getItem('publicKey');

let crypt = new Crypt({
    md: 'sha256',
    rsaStandard: 'RSA-OAEP',
});

export const encryptedUuid = (timestamp) => {
    if (uuid && publicKey && publicKey.includes('-----BEGIN PUBLIC KEY-----')) return crypt.encrypt(publicKey, `${uuid}@${timestamp.getTime()}`);
    else return false;
}