import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DeleteIcon from '@material-ui/icons/Delete';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor: '#000',
    },
    content: {
        padding: theme.spacing(2),
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

export default function BotCard(props) {
    const classes = useStyles();
    const bot = props.bot;
    const mode = props.mode;

    const [headless, setHeadless] = useState(bot.mode === 'auto');
    const [status, setStatus] = useState(bot.status);
    const [live, setLive] = useState('');
    const [statusMsg, setStatusMsg] = useState(bot.status);

    useEffect(() => {
        let msg;

        setStatus(bot.status);
        setLive(bot.live);
        setHeadless(bot.mode === 'auto');

        msg = (bot.status === 'new') ? 'new' :
            (bot.status === 'new-disabled') ? 'disabled' :
            (bot.status === 'disabled') ? 'disabled' :
            (bot.live === '') ? 'enabled' :
            (bot.live === 'stopped') ? <span><span>{bot.status} / </span><span style={{ color: '#f00' }}>stopped</span></span> : 'enabled / running';

        setStatusMsg(msg);
        
        return () => {
        }
    }, [bot]);

    const toggleMode = () => {
        let newMode = !headless;

        setHeadless(newMode);

        props.callback(bot.botID, 'headless', newMode);
    }

    const toggleState = () => {
        let newStatus;

        if (status === 'enabled') newStatus = 'disabled';
        else if (status === 'new') newStatus = 'new-disabled';
        else if (status === 'new-disabled') newStatus = 'new';
        else if (status === 'disabled') newStatus = 'enabled';

        setStatus(newStatus);
        setLive('');

        props.callback(bot.botID, 'status', newStatus);
    }

    const handleDelete = () => {
        props.callback(bot.botID, 'delete');
    }

    const goDashboard = () => {
        props.callback(bot.botID, 'dashboard');
    }

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Paper>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box className={classes.cardHeader}>
                            <Typography gutterBottom variant="button" component="h2">
                                <Box color="#FFF" ml={2} mr={2}>{bot.botname}</Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Typography variant="button">
                            <div>Bot ID: <b>{bot.botID}</b></div>
                            <div><span>Status: <b>{statusMsg}</b></span></div>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.content}>
                    <Grid item xs={4}>
                        <Button variant="contained" onClick={goDashboard} disabled={status === 'disabled' || live !== 'running'}>
                            Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    {(status === 'new' || status === 'new-disabled') ? (
                        <Grid item xs={2}></Grid>
                    ): (
                        <Grid item xs={2}>
                            {(headless) ? (
                                <LightTooltip title="Switch to manual mode">
                                    <Button size="small" color="primary" onClick={toggleMode}>
                                        <FontDownloadIcon color={(mode === 'light') ? "primary" : "secondary"} />
                                    </Button>
                                </LightTooltip>
                            ) : (
                                <LightTooltip title="Switch to automatic mode">  
                                    <Button size="small" color="primary" onClick={toggleMode}>
                                        <FontDownloadIcon color="disabled" />
                                    </Button>
                                </LightTooltip>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={2}>
                        {(status === 'disabled' || status === 'new-disabled') ? (
                            <LightTooltip title="Start bot">
                                <Button size="small" color="primary" onClick={toggleState}>
                                    <PowerSettingsNewIcon color="disabled" />
                                </Button>
                            </LightTooltip>
                        ) : (
                            <LightTooltip title="Stop bot">
                                <Button size="small" color="primary" onClick={toggleState}>
                                    <PowerSettingsNewIcon color={(mode === 'light') ? "primary" : "secondary"} />
                                </Button>
                            </LightTooltip>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <LightTooltip title="Delete bot">
                            <Button size="small" color={(mode === 'light') ? "primary" : "secondary"} onClick={handleDelete}>
                                <DeleteIcon />
                            </Button>
                        </LightTooltip>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}