import React, { useState, createContext } from 'react';

export const ServerContext = createContext();

export const ServerProvider = (props) => {
    const [server, setServer] = useState({
        status: 'disconnected',
        updated: '---',
        serverversion: '0.0',
        servertime: '---',
        access: { allow: false, reason: 'initial state', token: null },
        externalip: '',
        publicKey: '',
        userid: null,
    });

    return (
        <ServerContext.Provider value={[server, setServer]}>
            {props.children}
        </ServerContext.Provider>
    )
}