import React, { useState, createContext } from 'react';

export const ConfigurationContext = createContext();

export const ConfigurationProvider = (props) => {
    const [configuration, setConfiguration] = useState({});

    return (
        <ConfigurationContext.Provider value={[configuration, setConfiguration]}>
            {props.children}
        </ConfigurationContext.Provider>
    )
}