import { useEffect, useContext } from "react";
import { socket } from '../context/SocketContext';
import { ServerContext } from '../context/ServerContext';
import { MessageContext } from '../context/MessageContext';
import { ConfigurationContext } from '../context/ConfigurationContext';
import { BotsContext } from '../context/BotsContext';
import { useLocalStore } from "../services/LocalStoreService";
import { v4 as uuidv4 } from 'uuid';
import pckg2 from '../version2.json';

export default function SocketService() {
    const version = pckg2.version;

    const [{ status, serverversion, publicKey }, setServer] = useContext(ServerContext);
    const setMessages = useContext(MessageContext)[1];
    const setConfiguration = useContext(ConfigurationContext)[1];
    const setBots = useContext(BotsContext)[1];
    const [pubKey, setPubKey] = useLocalStore('publicKey', '');
    const [uuid, setUuid] = useLocalStore('uuid', '');

    useEffect(() => {
        if (pubKey !== undefined && publicKey && publicKey.includes('-----BEGIN PUBLIC KEY-----')) {
            if (publicKey !== pubKey) {
                setPubKey(publicKey);
                // This may happen because of a clean BB reinstall, which generates a new public key
                // Or when running on a new client, or when local storage is cleared by the user
                // Reload client, so the UUID gets encrypted with the new public key when establishing the websocket connection
                // Do so after short delay, to make sure the public key gets stored
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                // Reload page on server startup if new BB version has been installed, to make sure server and client are in sync
                if (status === 'starting' && serverversion !== '0.0' && version !== serverversion) window.location.reload();
                else if (status === 'starting')
                    setServer(prevState => {
                        return {
                            ...prevState,
                            status: 'connected'
                        }
                    });
            }
        }

        return () => {
        }
    }, [publicKey, pubKey, status, serverversion]);

    useEffect(() => {
        if (uuid === '') {
            setUuid(uuidv4());
            // Reload after short delay, to make sure the UUID gets stored
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }

        return () => {
        }
    }, []);

    useEffect(() => {
        socket.on("status", (server) => {
            setServer(prevState => {
                return {
                    ...prevState,
                    ...server
                }
            });
        });
        socket.on("message", (msg) => {
            setMessages(msg);
        });
        socket.on("settings", (config) => {
            if (config.restored && config.restored.password) config.bbPassword = '';
            if (config.restored && config.restored.telegram) config.telegramToken = ''; 
            setConfiguration(config);
        });
        socket.on("bots", (config) => {
            setBots(config);
        });
        socket.on("disconnect", data => {
            setServer(prevState => {
                return {
                    ...prevState,
                    status: 'disconnected',
                }
            });
        });

        return () => {
            socket.disconnect();
        }
    }, []);

    return (null);
}