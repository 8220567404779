import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link2 from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useLocalStore } from "../services/LocalStoreService";
import { ServerContext } from '../context/ServerContext';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import pckg2 from '../version2.json';
import { socket } from '../context/SocketContext';
import { Crypt } from 'hybrid-crypto-js';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
        maxWidth: 60,
        padding: 10,
    },
    content: {
        justify: "flex-start",
        alignItems: "flex-start",
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    footer: {
        marginTop: 'auto',
        backgroundColor: '#000000',
    },
    bottomPush: {
        position: "fixed",
        width: '100%',
        bottom: 0,
        paddingBottom: 0,
        zIndex: theme.zIndex.drawer + 1,
    },
}));

function Copyright() {
    return (
        <Box color="#FFF" height={54} p={2}>
            <Typography variant="body2" align="center">
                {'Copyright © '}
                <Link2 style={{ color: '#ffffff', textDecoration: 'none' }} href="https://hodlerhacks.com/" target="_blank" rel="noopener">
                    hodlerhacks.com
                </Link2>{' '}
                {new Date().getFullYear()}
            </Typography>
        </Box>
    );
}

export default function Public() {
    const version = pckg2.version;

    const mode = useLocalStore('mode', 'light')[0];
    const { status, access, publicKey } = useContext(ServerContext)[0];
    const [loginNow, setLoginNow] = useState(false);
    const [accessMsg, setAccessMsg] = useState('initial state');
    const [serverDown, setServerDown] = useState(false);
    const [usernameProvided, setUsernameProvided] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const getUsername = () => {
        const pathname = window.location.pathname;
        const result = pathname.match(/user\/(\w{3,})/);

        if (result)
            return result[1];
        else
            return '';
    }

    let crypt = new Crypt({
        md: 'sha256',
        rsaStandard: 'RSA-OAEP',
    });

    useEffect(() => {
        document.title = `BB | Bot Dashboard`;

        let user = getUsername();

        if (user) {
            setUsername(user);
            setUsernameProvided(true);
        }

        return () => {
        }
    }, []);

    useEffect(() => {
        if (status === 'disconnected')
            // If status remains in disconnected state for too long, the server is down
            setTimeout(() => {
                if (status === 'disconnected') setServerDown(true);
            }, 5000);
        else
            setServerDown(false);

        return () => {
        }
    }, [status]);

    useEffect(() => {
        if (access) {
            setLoginNow(false);
            setServerDown(false);
            if (access.allow === true) {
                setAccessMsg('');
            } else if (access.allow === false && access.reason === 'login required') {
                setAccessMsg("Please log in");
                setLoginNow(true);
            } else if (access.allow === false && access.reason === '2FA required') {
                setAccessMsg("Add your client's IP address to the IP whitelist, or temporarily allow access through your Telegram bot");
            } else if (access.allow === false && access.reason === 'temporary access not allowed') {
                setAccessMsg("Add your client's IP address to the IP whitelist");
            } else if (access.allow === false && access.reason === 'access timeout') {
                handleRefresh();
            } else if (access.allow === false && access.reason === 'temporary access timeout') {
                setAccessMsg("Your temporary access has expired");
            } else if (access.allow === false && access.reason === '2FA login required') {
                setAccessMsg("2FA required - approve login through your Telegram bot");
            } else if (access.allow === false && access.reason === 'invalid credentials') {
                setAccessMsg("Incorrect username or password - please try again");
                setLoginNow(true);
            } else if (access.allow === false && access.reason === '2FA login rejected') {
                setAccessMsg("2FA login was rejected");
            } else if (access.allow === false && access.reason === 'temporary access rejected') {
                setAccessMsg("Temporary access was rejected");
            } else if (access.allow === false && access.reason === 'logged out') {
                handleRefresh();
            } else if (access.allow === false && access.reason === 'initial state') {
                setAccessMsg("initial state");
                // If access.reason remains in intial state for too long, the server is down
                setTimeout(() => {
                    if (access.reason === 'initial state') setServerDown(true);
                }, 4000);
            } else if (access.allow === false && access.reason === 'reload') {
                handleRefresh();
            }
        }

        return () => {
        }
    }, [access]);

    const theme = createMuiTheme({
        palette: {
            type: mode
        }
    });

    const classes = useStyles();

    const onLoginInput = (e) => {
        if (e.target.id === 'username') setUsername(e.target.value);
        if (e.target.id === 'password') setPassword(e.target.value);
    }

    const onSignIn = () => {
        const timestamp = new Date().getTime();

        if (socket) socket.emit("login", {
            username: username,
            password: crypt.encrypt(publicKey, `${password}@${timestamp}`),
        });
    }

    const handleRefresh = () => {
        window.location.reload();
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <React.Fragment>
            <Button
                style={{
                    color: '#bbbbbb',
                }}
                onClick={() => { closeSnackbar(key) }}>
                Dismiss
            </Button>
        </React.Fragment>
    );

    const handleForgotPassword = () => {
        if (socket) socket.emit("reset-password");
        enqueueSnackbar('See your Telegram bot to reset password', {
            variant: 'success',
            autoHideDuration: 8000,
            action,
        });
    }

    return (<ThemeProvider theme={theme}>
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <img src="/logo192.png" alt="Balance Bot Logo" className={classes.logo} />
                        <Grid container direction="row" alignItems="center" nowrap="true">
                            <Grid item xs zeroMinWidth>
                                <Box mt={1.5}>
                                    <Typography variant="h6" color="inherit" noWrap>
                                        Balance Bot
                                    </Typography>
                                </Box>
                                <Box mt={-1} style={{ color: '#959FD6' }}>
                                    <Typography variant="caption" color="inherit" noWrap>
                                        v{version}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <Toolbar />
                </main>
            </div>
            {/* </BrowserRouter> */}
        </React.Fragment>
        {status === 'disconnected' && accessMsg !== 'initial state' ? (
            <Dialog
                open={serverDown}
                fullWidth = {true}
                maxWidth = {'sm'}
                    >
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="overline" color="secondary">
                            The connection with the server has been lost
                        </Typography>
                    </DialogContentText>
                    <DialogContentText>
                        <Typography variant="overline" color="textPrimary">
                            Balance Bot may have stopped running
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleRefresh} color="secondary">
                        Retry
                    </Button>
                </DialogActions>
            </Dialog>
            ) : !loginNow ? (accessMsg === 'initial state') ? (
            <Dialog
                open={serverDown}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="overline" color="secondary">
                            Balance Bot seems to be down
                        </Typography>
                    </DialogContentText>
                    <DialogContentText>
                        <Typography variant="overline" color="textPrimary">
                            Please check your server and try again
                        </Typography>
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button variant="contained" onClick={handleRefresh} color="secondary">
                        Retry
                    </Button>
                </DialogActions>
            </Dialog >
            ) : !access.allow && (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="overline" color="secondary">
                            Notification
                        </Typography>
                    </DialogContentText >
                    <DialogContentText>
                        <Typography variant="overline" color="textPrimary">
                            {accessMsg}
                        </Typography>
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button variant="contained" onClick={handleRefresh} color="secondary">
                        Back
                    </Button>
                </DialogActions>
            </Dialog >
        ) : (
            <React.Fragment>
                <CssBaseline />
                <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle>{accessMsg}</DialogTitle>
                    <DialogContent>
                        {!usernameProvided && (
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                onChange={onLoginInput}
                            />
                        )}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={onLoginInput}
                        />
                        <Box m={3} />
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={onSignIn}
                        >
                            Log In
                        </Button>
                        <Box m={3} />
                        <Button fullWidth onClick={handleForgotPassword} color="default" style={{ fontSize: '10px' }}>
                            Forgot password
                        </Button>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
        }
        <div className={classes.bottomPush}>
            <footer className={classes.footer}>
                <Grid container alignItems="center">
                    <Grid item xs={false} sm={12} md={12}>
                        <Copyright />
                    </Grid>
                </Grid>
            </footer>
        </div>
    </ThemeProvider >
    )
}
